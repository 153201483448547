//
//  Base Styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import 'utils';

//
//  Common
//  ----------------------------------------------

   //Admin
.amblog-disabled {
    color: map-get($theme-colors, 'negative');
}

.amblog-gdprtext {
    display: flex;

    input {
        margin-right: 10px;
    }
}

.amblog_views_label,
.amblog_likes_label,
.amblog_dislikes_label {
    font-weight: 600;
    line-height: 3.2rem;
}

.amblog-hidden {
    color: grey
}

.amblog-published {
    color: green
}

.amblog-scheduled {
    color: grey
}

.pagebuilder-content-type .amblog_widget .amblog-widget-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    background-color: $amblog__background-color;
}

#amblog_canvas_container {
    max-width: 400px;
    max-height: 400px;
}

//Front
.tag-list.cloud-enabled .amblog-item {
    display: none;
}

.amblog-post-list .block-title strong {
    font-weight: 600;
}

.amblog-preloader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .6);
}

.amblog-loader,
.amblog-slick-slider:not(.-am-loaded):after {
    margin: auto;
    width: 16px;
    height: 11px;
    background: url('../../images/ajax-loader.gif') no-repeat scroll 50% 0 transparent;
}

@mixin word-wrap {
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: anywhere;
}

.amblog-index-index .page-title,
.amblog-index-post .page-title {
    @include word-wrap();
    margin-bottom: 0;
    padding-right: 50px;
    font-size: 40px;
}

.mobile {
    display: none;

    .mobile-block {
        display: none;
    }
}

.mobile.two-columns-right .content,
.mobile.two-columns-left .content {
    position: relative;
}

.amblog-main-content {
    padding-top: 40px;


    > .amblog-item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .amblog-btn {
        // height: auto;
    }
}

.amblog-aside-right,
.amblog-aside-left {
    position: fixed;
    top: 0;
    z-index: 9999;
    display: none;
    overflow-y: scroll;
    padding-top: 20px;
    width: 300px;
    height: calc(100% - 20px);
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 98, 176, .1);


    &.active {
        display: block;
    }

    .amblog-close {
        position: relative;
        width: 15px;
        height: 15px;
        margin: 0 0 25px 15px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 13px;
            background: $amblog-gray__color;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.amblog-aside-right {
    right: 0;
}

.amblog-aside-left {
    left: 0;
}

.amblog-overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(60, 60, 60, .3);
}

.amblog-index-index .amblog-ref,
.amblog-index-tag .amblog-ref,
.amblog-index-category .amblog-ref,
.amblog-index-post .amblog-ref,
.amblog-index-author .amblog-ref {
    font-size: 14px;
    font-weight: 600;
    color: $amblog-blue__color;

    &:hover {
        color: $amblog-blue__color;
    }
}

.amblog-cloud-tags {
    width: auto;

    .amblog-cloud {
        width: 100%;
    }
}

.amblog-author-container,
.amblog-element-block .amblog-author,
.amblog-comment-content .amblog-author,
.amblog-post-container .amblog-item.-author,
.amblog-post-container .amblog-item.-comments,
.amblog-post-container .amblog-item.-print {
    @include word-wrap();
    line-height: 25px;
    font-size: 14px;
    color: $amblog-gray__color;
}

.amblog-wrap {
    display: flex;
}

.amblog-svg {
    position: relative;
    top: 7px;
    min-width: 25px;
    margin-right: 10px;

    &.user {
        width: 25px;
        height: 25px;
    }

    &.comment {
        top: 6px;
        width: 25px;
        height: 24px;
    }

    &.print {
        width: 21px;
        min-width: auto;
        height: 24px;
    }

    &.-classic {
        fill: $amblog-svg-custom-classic__color;
    }

    &.-red {
        fill: $amblog-svg-custom-red__color;
    }

    &.-green {
        fill: $amblog-svg-custom-green__color;
    }

    &.-blue {
        fill: $amblog-svg-custom-blue__color;
    }

    &.-grey {
        fill: $amblog-svg-custom-grey__color;
    }

    &.-purple {
        fill: $amblog-svg-custom-purple__color;
    }
}

.amblog-swipe.-left,
.amblog-swipe.-right {
    position: absolute;
    top: 85px;
    right: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    transition: all .5s .3s ease-in-out;
    background-image: $amblog-swipe__background-image;
    box-shadow: 0 4px 8px rgba(0, 98, 176, .2);
}

.amblog-element-block .amblog-tag,
.amblog-post-container .amblog-tag {
    @include word-wrap();
    display: inline-block;
    margin: 0 10px 5px 0;
    padding: 3px 8px;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    background: $amblog-tag__color;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        text-decoration: none;
    }
}

.amblog-post-container,
.amblog-grid-list > .amblog-item,
.amblog-grid-list .slick-slide .amblog-item,
.amblog-main-content .amblog-search {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border: 1px solid $amblog-border__color;
    border-radius: 4px;

    &:last-child {
        margin-bottom: 0;
    }

    .amblog-tags {
        margin: 20px 0;
        padding: 0 15px;
    }

    .amblog-title {
        @include word-wrap();
        margin: 0 0 20px;
        font-size: 24px;
        color: $amblog-gray__color;
        font-weight: 600;
    }

    .amblog-image {
        border-radius: 4px;
    }

    .amblog-content {
        margin-top: 15px;
        padding: 0 15px 15px;
    }

    .amblog-widget-container {
        margin: 20px -15px;
    }

    .amblog-headline {
        color: $amblog-gray__color;

        &:hover {
            color: $amblog-blue__color;
        }
    }

    .amblog-shot {
        margin: 15px 0;
        font-size: 16px;
        color: $amblog-gray__color;
    }

    .amblog-shot > p,
    .amblog-content > p {
        @include word-wrap();
    }

    .amblog-bottom {
        display: flex;
        align-items: baseline;
        margin-top: 20px;
    }

    .amblog-bottom .amblog-views {
        padding-left: 5px;
    }

    .amblog-date {
        margin-right: 15px;
        font-size: 14px;
        color: $amblog-date__color;
    }

    .amblog-views {
        font-size: 14px;
        color: $amblog-date__color;
        white-space: nowrap;
    }

    .amblog-footer {
        padding: 15px 15px 20px;
    }

    .amblog-features {
        display: flex;
        flex-wrap: wrap;

        &.-post {
            padding: 15px 15px 20px;
        }
    }

    .amblog-item.-print {
        margin: 0 0 0 auto;
        white-space: nowrap;
    }

    .amblog-category {
        @include word-wrap();
        margin: 5px 30px 5px 0;
        line-height: 25px;
        font-size: 14px;
        color: $amblog-gray__color;
    }

    .amblog-item.-author-posts {
        @include word-wrap();
        margin: 5px 30px 5px 0;
        line-height: 25px;
        font-size: 14px;
        color: $amblog-gray__color;
    }

    .amblog-read {
        @extend %button-base;
        @extend %button-shout;

        width: 100%;
        margin-top: 20px;
        max-width: none;

        .amblog-arrow {
            display: none;
        }
    }
}

.amblog-post-container .amblog-widget-container.-post .amblog-item,
.amblog-post-container .amblog-item,
.amblog-grid-list > .amblog-item,
.amblog-grid-list .slick-slide .amblog-item,
.amblog-main-content .amblog-search {
    margin: 5px 31px 5px 0;
}

.amblog-post-container .amblog-features.-post .amblog-item {
    padding: 0;
}

.amblog-recent-post {
    display: flex;

    .amblog-recent-post-image-wrapper {
        margin-right: 5%;
        vertical-align: top;
        min-width: 20%;
    }
}

.amblog-element-block .amblog-title,
.amblog-main-content .amblog-search .amblog-title {
    position: relative;
    padding: 20px 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $amblog-blue__color;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 30px;
        height: 2px;
        width: 10px;
        background: $amblog-blue__color;
    }

    &:before {
        right: 26px;
        transform: rotate(45deg);
    }

    &:after {
        right: 20px;
        transform: rotate(-45deg);
    }

    &.-active {
        border-bottom: 1px solid $amblog-border__color;

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }
}

.amblog-element-block .amblog-item,
.amblog-element-block .amblog-categories.-level1 > .amblog-category {
    margin-bottom: 0;
    padding: 15px 25px;
    border-bottom: 1px solid $amblog-border__color;

    &:last-child {
        border-bottom: none;
    }

    &.-search {
        padding: 20px 20px;
    }

    &.-comment:hover {
        background: #f1f8ff;
    }
}

.amblog-element-block .amblog-headline,
.amblog-element-block .amblog-categories .amblog-link {
    @include word-wrap();
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: $amblog-gray__color;

    &:hover {
        color: $amblog-blue__color;
    }
}

.amblog-element-block,
.amblog-post-container .amblog-widget-container {
    display: block;
    border-bottom: 1px solid $amblog-border__color;

    .columns .column.main > & {
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    .amblog-btn {
        // height: auto;
    }

    .amblog-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .amblog-empty {
        padding: 20px;
    }

    .amblog-date {
        margin: 0;
        font-size: 14px;
        color: $amblog-date__color;
    }

    .amblog-date.-post {
        margin-top: 5px;
    }

    .amblog-date.-comment {
        margin: 10px 0;
    }

    .amblog-thesis {
        @include word-wrap();
        margin: 5px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .amblog-comment {
        @include word-wrap();
        margin: 10px 0;
        font-style: italic;
        font-size: 16px;
    }

    .amblog-author {
        display: flex;
        align-items: baseline;
        margin-top: 5px;
    }
}

.amblog-element-block .amblog-categories {
    margin: 0;
    padding: 0;
    list-style: none;

    &.-level1 .amblog-categories {
        padding-left: 20px;
    }

    .amblog-link {
        font-weight: normal;
    }

    &.-level1 > .amblog-category > .amblog-link {
        font-weight: 600;
    }

    &.-level3 .amblog-link {
        font-size: 14px;
    }

    &.-level2 .amblog-category {
        margin: 10px 0 0;
    }

    &.-level3 .amblog-category {
        margin-top: 5px;
    }
}

.amblog-form-comment .amblog-input,
.amblog-form-search .amblog-input {
    width: 100%;
    height: 100%;
    padding: 9px 10px 9px 36px;
    border-radius: 3px;
    font-size: 14px;
    border: 1px solid $amblog-border__color;
    background: #f9f9f9;
    color: $amblog-input-text__color;

    &::-webkit-input-placeholder {
        color: $amblog-input-text__color;
    }

    &::-moz-placeholder {
        color: $amblog-input-text__color;
    }

    &:-ms-input-placeholder {
        color: $amblog-input-text__color;
    }

    &:focus {
        border: 1px solid #499bf8;
        background: #f1f8ff;
        box-shadow: none;

        &:focus::-webkit-input-placeholder {
            color: transparent
        }

        &:focus::-moz-placeholder {
            color: transparent
        }


        &:focus:-ms-input-placeholder {
            color: transparent
        }
    }

}

.amblog-form-comment .amblog-btn,
.amblog-form-search .amblog-btn {
    @extend %button-base;
    @extend %button-shout;
}

.amblog-form-search {
    .amblog-field {
        position: relative;
        margin-bottom: 10px;

        &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 10px;
            width: 16px;
            height: 16px;
            background-image: $amblog-loupe-icon__background-image;
        }
    }

    .amblog-input-wrapper {
        display: inline-block;
        width: 100%;
    }

    .amblog-btn {
        width: 100%;
    }
}

.amblog-main-content .amblog-search {
    box-shadow: 0 4px 8px rgba(0, 98, 176, .1);

    .amblog-item {
        padding: 20px 15px;
    }

    .amblog-title {
        padding: 15px 15px;
        border-bottom: 1px solid $amblog-border__color;
    }
}

.amblog-grid-list {
    padding: 0;
    list-style: none;


    .slick-slide .amblog-item {
        width: 100%;
    }

    .slick-slide .amblog-item {
        box-sizing: border-box;
        border-bottom: 1px solid $amblog-border__color;
    }

    .amblog-item.-comments {
        white-space: nowrap;
    }

    .image {
        border-radius: 4px;
    }

    > .amblog-item .amblog-footer {
        padding-bottom: 5px;
    }

    > .amblog-item .amblog-footer .amblog-features {
        align-items: center;
    }

    > .amblog-item .amblog-footer .amblog-features .amblog-category {
        margin-top: 0;
        margin-bottom: 0;
    }

    > .amblog-item .amblog-footer .amblog-features .amblog-wrap .amblog-svg.comment {
        top: 0;
    }
}

.amblog-social-container,
.amblog-element-block[data-set="sidebar-author"],
.amblog-item[data-set="content-author"] {
    padding: 15px 15px 0;

    .amblog-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .amblog-social {
        display: block;
        width: 25px;
        height: 25px;
    }

    .amblog-icon {
        width: 25px;
        height: 25px;
        margin: 0 15px 5px 0;
        border-radius: 50%;
        background-color: $amblog-social-icon__background-color;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .amblog-icon:hover {
        background-color: $amblog-social-icon__hover__background-color;
    }

    .amblog-icon.-twitter {
        background-image: $amblog-social-twitter__background-image;
    }

    .amblog-icon.-facebook {
        background-image: $amblog-social-facebook__background-image;
    }

    .amblog-icon.-vkontakte {
        background-image: $amblog-social-vkontakte__background-image;
    }

    .amblog-icon.-digg {
        background-image: $amblog-social-digg__background-image;
    }

    .amblog-icon.-pinterest {
        background-image: $amblog-social-pinterest__background-image;
    }

    .amblog-icon.-blogger {
        background-image: $amblog-social-blogger__background-image;
    }

    .amblog-icon.-linkedin {
        background-image: $amblog-social-linkedin__background-image;
    }

    .amblog-icon.-tumblr {
        background-image: $amblog-social-tumblr__background-image;
    }

    .amblog-icon.-reddit {
        background-image: $amblog-social-reddit__background-image;
    }

    .amblog-icon.-slashdot {
        background-image: $amblog-social-slashdot__background-image;
    }

    .amblog-icon.-stumbleupon {
        background-image: $amblog-social-stumbleupon__background-image;
    }

    .amblog-icon.-odnoklassniki {
        background-image: $amblog-social-odnoklassniki__background-image;
    }

    .amblog-icon.-google {
        background-image: $amblog-social-google__background-image;
    }

    .amblog-icon.-whatsapp {
        background-image: $amblog-social-whatsapp__background-image;
        background-size: 15px;
    }
}

.amblog-pager-container {
    margin: 20px auto 50px;

    .amblog-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .amblog-link {
        position: relative;
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: $amblog-blue__color;

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 2px;
            background: #fff;
        }

        &:before {
            top: 13px;
            left: 13px;
            transform: rotate(45deg);
        }

        &:after {
            top: 20px;
            left: 13px;
            transform: rotate(-45deg);
        }

        &:hover {
            background: $amblog-link__hover__color;
        }
    }

    .amblog-link.-new:before {
        left: 10px;
        transform: rotate(-45deg);
    }

    .amblog-link.-new:after {
        left: 10px;
        transform: rotate(45deg);
    }

    .amblog-link.-disabled {
        cursor: auto;
        pointer-events: none;
        background: #c4c4c4;
    }

    .amblog-pagination {
        font-size: 16px;
        color: $amblog-gray__color;
    }
}

.amblog-comments-wrapper,
.amblog-related-wrapper {
    margin-bottom: 31px;
    border: 1px solid $amblog-border__color;
    border-radius: 4px;

    > .amblog-title {
        position: relative;
        padding: 20px 20px;
        border-bottom: 1px solid $amblog-border__color;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: $amblog-blue__color;
    }
}

.amblog-form-comment {
    position: relative;
    display: flex;
    flex-direction: column;

    .amblog-svg.comment {
        position: absolute;
        top: -40px;
        left: 0;
    }

    .amblog-fieldset {
        margin: 0;
        padding: 0;
        border: none;
        min-inline-size: initial;
    }

    .amblog-customer {
        display: flex;
        flex-direction: column;
    }

    .amblog-customer.-hidden {
        display: none;
    }

    .amblog-wrapper {
        margin-bottom: 15px;
    }

    .amblog-input {
        padding-left: 15px;
        background-color: #fff;

        &.-name,
        &.-email {
            max-height: 40px;
        }
    }

    .amblog-input.-textarea {
        margin-bottom: 0;
        height: 100px;
    }

    .amblog-gdprtext {
        position: relative;
        margin-top: 15px;
    }

    .amblog-gdprtext .amblog-label {
        @include word-wrap();
        word-wrap: anywhere;
    }

    .amblog-input.-gdpr {
        position: relative;
        top: 2px;
        width: auto;
        height: 13px;
    }

    .mage-error {
        margin-bottom: 0;
    }

    .amblog-btn {
        margin-top: 15px;
    }
}

.amblog-widget-container {
    .amblog-list {
        padding-left: 40px;
    }
}

.amblog-element-block .amblog-widget-container {
    &.-post .amblog-list,
    &.-comments .amblog-list,
    &.-categories .amblog-list {
        padding-left: 0;
    }
}

.amblog-post-container .amblog-widget-container {
    padding-bottom: 25px;
    border: none;
    background: #f7fbff;

    .amblog-title {
        padding: 25px 20px 0;
        border-bottom: none;

        &:before,
        &:after {
            content: none;
        }
    }

    .amblog-list {
        margin: 0 20px 0;
        border: 1px solid $amblog-border__color;
        border-radius: 4px;
        background: #fff;
    }

    .amblog-item {
        margin: 0;
    }
}

.amblog-post-container .amblog-widget-container.-post {
    padding-bottom: 10px;

    .amblog-list {
        border: none;
        background: inherit;
    }

    .amblog-item {
        margin: 0 0 15px;
    }
}

.amblog-post-container .amblog-widget-container.-tags {
    padding: 0 20px 15px;

    .amblog-title {
        margin-bottom: 15px;
        padding: 25px 0 0;
    }

    .amblog-tag {
        margin-bottom: 10px;
    }

    .amblog-item {
        padding: 0;
    }

    .amblog-cloud-tags {
        width: 200px;
        margin: 0 auto;
    }
}

.amblog-post-container .amblog-widget-container.-categories {
    .amblog-category {
        margin-right: 0;
    }
}

.amblog-helpful-wrap {
    padding: 15px 25px 0;
    text-align: left;

    > .amblog-helpful {
        font-size: 14px;
        color: $amblog-helpful-text__color;
        vertical-align: top;
    }

    > .amblog-options {
        margin-left: 5px;
    }

    > .amblog-options,
    .amblog-plus,
    .amblog-minus,
    .amblog-icon {
        display: inline-block;
        cursor: pointer;
    }

    .amblog-icon {
        margin: 0 5px 0 10px;
        width: 20px;
        height: 18px;
        transition: all .3s ease;
    }

    .amblog-plus > .amblog-icon {
        background: $amblog-like__background;
    }

    .amblog-plus:hover > .amblog-icon {
        background: $amblog-like__hover__background;
    }

    .amblog-plus.-voted > .amblog-icon {
        background: $amblog-like__active__background;
    }

    .amblog-minus > .amblog-icon {
        background: $amblog-dislike__background;
    }

    .amblog-minus:hover > .amblog-icon {
        background: $amblog-dislike__hover__background;
    }

    .amblog-minus.-voted > .amblog-icon {
        background: $amblog-dislike__active__background;
    }
}

.amblog-container-list .amblog-author-description {
    margin-bottom: 60px;
}

.amblog-category-description {
    margin-bottom: 20px;
}

// Styles for post slider
.amblog-post-list {
    margin-top: 30px;

    .slick-track {
        display: flex;
    }

    .slick-slide {
        margin: 0 10px;
        text-align: center;
    }

    .post-item-details {
        @include am-word-break;

        margin-top: 10px;
    }
}

.amblog-element-block[data-set="sidebar-author"] {
    .author_image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
    }

    .title {
        font-weight: bold;
        text-align: center;
        font-size: large;
    }
}

.amblog-notify-message {
    @include ms-icon('info');
}

// Related posts
.amblog-relatedposts-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.amblog-related-post {
    display: flex;
    margin-bottom: 35px;
    width: 49%;

    .amblog-link {
        display: block;
    }

    .amblog-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-sizing: border-box;
        margin-right: 20px;
        min-width: $amblog-post-image__size;
        width: $amblog-post-image__size;
        height: $amblog-post-image__size;
        background: $amblog-image-wrapper__background-color center/50px no-repeat;
    }

    .amblog-image-wrapper.-placeholder {
        background-image: $amblog-post-image__background-image;
    }

    .amblog-image {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .amblog-headline {
        display: block;
        color: #212121;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;

        &:hover,
        &:focus {
            color: #0e6bd7;
            text-decoration: none;
        }
    }

    .amblog-content {
        @include am-word-break;
    }

    .amblog-text {
        margin: 15px 0 0;
    }

    .amblog-date {
        display: block;
        margin-top: 15px;
        color: #707070;
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
    }
}


//
//  Mobile
//  --------------------------------------------

@include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
    body[class*="amblog"] {
        .sidebar-additional {
            display: block;
        }
        .sidebar.sidebar-main {
            width: 100%;
        }

        .sidebar-main .amblog-element-block .amblog-title,
        .sidebar-container .amblog-element-block .amblog-title {
            &:before,
            &:after {
                display: block;
            }
        }
    }

    .mobile {
        display: block;

        .mobile-block {
            display: block;
        }
    }

    .desktop {
        display: none;

        .desktop-block {
            display: none;
        }
    }

    .amblog-relatedposts-wrap {
        display: block;
    }

    .amblog-related-post {
        margin-bottom: 20px;
        width: 100%;
    }
}

//
//  Tablet
//  --------------------------------------------

@include respond-to-up($tweakpoint-nav-toggle) {
    .amblog-index-index .page-title,
    .amblog-index-post .page-title {
        margin-bottom: 40px
    }

    .desktop.three-columns {
        display: flex;
    }

    .desktop.three-columns section.content {
        width: 52%;
        padding: 0 30px;
    }

    .desktop.three-columns {
        aside.left,
        aside.right {
            width: 24%;
        }
    }

    .desktop.two-columns-right,
    .desktop.two-columns-left {
        display: flex;
    }

    .desktop.two-columns-right section.content {
        width: 76%;
        padding-right: 40px;
    }

    .desktop.two-columns-left section.content {
        width: 76%;
        padding-left: 40px;
    }

    .desktop.two-columns-right,
    .desktop.two-columns-left {
        aside.left,
        aside.right {
            width: 24%;
        }
    }

    .amblog-container-list {
        padding-top: 0;
    }

    .amblog-main-content .amblog-search .amblog-title,
    .amblog-element-block .amblog-title {
        padding: 15px 15px;

        &:before,
        &:after {
            display: none;
        }
    }

    .amblog-element-block {
        display: block;
        margin-bottom: 30px;
        border: 1px solid $amblog-border__color;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 98, 176, .1);

        .amblog-item {
            padding: 15px 20px;
        }

        .amblog-item.-search {
            padding: 20px 15px;
        }
    }

    .amblog-post-container {
        padding-bottom: 15px;
        margin-bottom: 40px;

        .amblog-tags {
            margin: 25px 0;
            padding: 0 25px;
        }

        .amblog-content {
            margin-top: 20px;
            padding: 0 25px 15px;
        }

        .amblog-widget-container {
            margin: 30px -25px;
        }

        .amblog-title {
            margin-bottom: 20px;
        }

        .amblog-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 25px;
        }

        .amblog-features.-post {
            padding: 15px 25px;
        }

        .amblog-more {
            min-width: 41%;
            text-align: right;
        }

        .amblog-read {
            margin: 0;
            padding-left: 5px;
            padding-right: 5px;
        }

        .amblog-arrow {
            display: none;
        }
    }

    .amblog-main-content .amblog-search {
        .amblog-item {
            padding: 30px 25px;
        }

        .amblog-title {
            padding: 20px 20px;
            border-bottom: 1px solid $amblog-border__color;
        }

        .amblog-form-container {
            display: flex;
        }

        .amblog-field {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .amblog-main-content {
        padding-top: 0;

        > .amblog-item {
            margin-bottom: 50px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .amblog-grid-list {
        display: flex;
        flex-wrap: wrap;

        > .amblog-item {
            width: 47%;
        }

        .amblog-features > .amblog-item {
            margin-right: 10px;
        }

        .amblog-features > .amblog-item.-comments .amblog-ref {
            white-space: nowrap;
        }

        .amblog-item .amblog-category {
            margin-right: 10px;
        }

        > .amblog-item .amblog-footer {
            padding-bottom: 5px;
        }
    }

    .amblog-social-container {
        padding: 15px 25px 0;
    }

    .amblog-pager-container {
        width: 290px;
        margin: 40px auto 50px;
    }

    .amblog-comments-wrapper,
    .amblog-related-wrapper {
        margin-bottom: 0;

        > .amblog-title {
            padding: 20px 30px;
        }
    }

    .amblog-form-comment {
        max-width: 480px;

        .amblog-svg.comment {
            top: 10px;
        }

        .amblog-customer {
            flex-direction: row;
        }

        .amblog-wrapper {
            width: 49%;

            &:first-child {
                margin: 0 20px 15px 0;
            }
        }

        .amblog-input {
            padding-left: 15px;
        }

        .amblog-input.-email + .mage-error,
        .amblog-input.-name + .mage-error {
            margin-bottom: 0;
        }
    }

    .amblog-post-container .amblog-widget-container {
        .amblog-title {
            padding: 25px 30px 0;
        }

        .amblog-list {
            margin: 0 30px 0;
        }
    }

    .amblog-post-container .amblog-widget-container.-post {
        .amblog-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .amblog-item {
            width: 47%;
            box-sizing: border-box;
        }
    }

    .desktop.one-column .amblog-post-container .amblog-content .amblog-widget-container.-post {
        .amblog-list {
            justify-content: inherit;
        }

        .amblog-item {
            width: 31%;
            margin: 0 20px 20px 0;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    .amblog-post-container .amblog-widget-container.-tags {
        padding: 0 30px 15px;
    }
}

@include respond-to-up($tweakpoint-nav-toggle) {
    .amblog-element-block .amblog-title {
        &:before,
        &:after {
            display: none;
        }
    }

    .amblog-index-index .page-title,
    .amblog-index-post .page-title {
        margin-bottom: 60px
    }

    .desktop.three-columns {
        aside.left,
        aside.right {
            max-width: 271px;
            width: 24%;
        }
    }

    .desktop.three-columns section.content {
        width: 52%;
    }

    .desktop.two-columns-right,
    .desktop.two-columns-left {
        aside.right {
            max-width: 271px;
            width: 30%;
        }
    }

    .amblog-post-container {
        .amblog-more {
            min-width: 30%;
        }
    }

    .amblog-form-comment .amblog-wrapper {
        width: 230px;
    }

    body .amblog-grid-list {
        justify-content: initial;
        align-items: unset;

        > .amblog-item {
            display: flex;
            flex-direction: column;
            width: 31%;
            min-width: 190px;
            margin-right: 3%;

            .amblog-tags,
            .amblog-content > .amblog-shot {
                display: none;
            }

            .amblog-content {
                flex: 1 1 auto;
            }

            .amblog-content > .amblog-title {
                margin-top: 10px;
            }
        }

        .slick-slide .amblog-item {
            width: 31%;
            min-width: 150px;
            margin-right: 3%;
        }

        > .amblog-item:nth-child(3n+0),
        .slick-slide .amblog-item:nth-child(3n+0) {
            margin-right: 0;
        }
    }

    .amblog-post-container .amblog-widget-container.-post .amblog-item {
        width: 48%;
    }

    .amblog-helpful-wrap {
        top: 0;
        right: 0;
        margin: 0;
    }

    body[class*="amblog"] {
        .sidebar-additional {
            display: block;
        }
    }

    body.page-layout-2columns-left,
    body.page-layout-2columns-right {
        .column.main {
            width: calc(300px - 1rem);
        }
        .sidebar-container {
            width: 300px;
        }
    }

    body.page-layout-2columns-left {
        .sidebar-container {
            margin-right: 1rem;
        }
    }

    body.page-layout-2columns-right {
        .sidebar-container {
            margin-left: 1rem;
        }
    }

    body.page-layout-3columns {
        .sidebar-container {
            margin: 0;
            width: 20%;
            order: 3;
        }
        .column.main {
            width: calc(100% - 40% - 2rem);
            margin-left: 1rem;
            margin-right: 1rem;
            order: 2;
        }
        .sidebar.sidebar-main {
            width: 20%;
            margin: 0;
            order: 1;
        }
    }
}
