//
//  Related items slider styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../utils';

//
//  Variables
//  --------------------------------------------

$amblog-slider-arrow: #fff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMTg5IDIuMDA5TDcuMTkgOS4wMS4yMzMgMTUuOTY4bDEuMTA4IDEuMTA5IDguMDQ0LTguMDQ0LTguMTEtOC4xMUwuMTg5IDIuMDA4eiIgZmlsbD0iIzU3NTg1NSIvPjwvc3ZnPg==') center no-repeat;
$amblog-slider-arrow__size: 30px;
$amblog-slider-dots__size: 16px;
$amblog-slider-dots__color: #c4c4c4;
$amblog-slider-dots__color__active: #000;

//
//  Common
//  --------------------------------------------

.amblog-slick-slider:not(.-am-loaded) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    min-height: 30px;

    &:after {
        display: block;
        content: '';
    }

    & > * {
        display: none;
    }
}

.main .amblog-related-wrapper:not(.-related-products) {
    margin-bottom: 40px;

    .amblog-related-grid {
        margin: 0;
        padding: 20px 10px;
    }

    .slick-list {
        margin: 0 10px;
        width: 100%;
    }

    .slick-list > .slick-track {
        margin-left: 0;
    }

    .slick-slide {
        margin: 0 7px;
        vertical-align: top;
    }
}

.amblog-related-wrapper.-related-products {
    margin-bottom: 40px;

    .amblog-grid-list {
        margin: 0;
        padding: 20px;
    }

    .amblog-item-product {
        margin: 0 10px;
    }

    .amblog-item-product .price-box {
        margin-bottom: 5px;
    }
}

.amblog-related-wrapper.-related-products .amblog-slick-slider {
    &.slick-dotted {
        margin-bottom: 30px;
    }

    .slick-slide {
        vertical-align: inherit;
    }

    .amblog-item-product {
        margin: 0;
        padding: 0 9px;
    }
}

.amblog-slick-slider.slick-slider .slick-dots {
    top: 100%;
    border: none;
    background: inherit;

    > li[role='presentation'] {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: $amblog-slider-dots__size;
        height: $amblog-slider-dots__size;
        border: none;
        text-align: inherit;
        white-space: inherit;

        &:hover,
        &.selected {
            background: inherit;
        }

        &.slick-active button {
            background: $amblog-slider-dots__color__active;
        }
    }

    > li[role='presentation'] button {
        padding: 4px;
        width: auto;
        height: auto;
        border: none;
        border-radius: 100%;
        background: $amblog-slider-dots__color;

        &:before {
            content: none;
        }
    }
}

.amblog-slick-slider.slick-slider .slick-arrow {
    z-index: 2;
    width: $amblog-slider-arrow__size;
    height: $amblog-slider-arrow__size;
    border-radius: 0;
    background: $amblog-slider-arrow !important; // Slick slider already has an important directive here. Override

    &:before,
    &:after {
        display: none;
    }

    &.slick-prev {
        transform: rotate(180deg) translate(0, 50%);
    }
}

//
//  Mobile only
//  --------------------------------------------

@include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
    .amblog-related-wrapper.-related-products .amblog-slick-slider.-am-loaded .amblog-item-product {
        display: block !important; // Override Slick js property
        margin: 0 auto;
        max-width: 300px;

        .product-item-photo {
            display: flex;
            justify-content: center;
        }

        .product-item-link {
            font-size: 20px;
        }
    }

    .main .amblog-related-wrapper:not(.-related-products) .slick-list {
        margin: 0;
    }
}

//
//  Tablet
//  --------------------------------------------

@include respond-to-up($tweakpoint-nav-toggle) {
    .desktop.three-columns .amblog-related-wrapper.-related-products .amblog-item-product {
        max-width: 129px;
    }

    .amblog-related-wrapper.-related-products .amblog-slick-slider:not(.slick-initialized) {
        justify-content: initial;

        .amblog-item-product {
            max-width: 25%;
        }
    }
}
