//
//  Author info styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../utils';

//
//  Variables
//  --------------------------------------------

$amblog-author-info__padding: 25px;
$amblog-social-icon__size: 40px;
$amblog-image-wrapper__size: 120px;
$amblog-image-wrapper__background-color: #f4f6f8;
$amblog-image-wrapper__background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48bWFzayBpZD0iYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjEyMCIgaGVpZ2h0PSIxMjAiPjxjaXJjbGUgY3g9IjYwIiBjeT0iNjAiIHI9IjYwIiBmaWxsPSIjQUFBIi8+PC9tYXNrPjxnIG1hc2s9InVybCgjYSkiIGZpbGw9IiNEN0Q5REQiPjxwYXRoIGQ9Ik02MC4wMDYgMzJjLTEzLjcyNSAwLTI0Ljk0NSAxMS4yMS0yNC45NDUgMjQuOTI1IDAgMTMuNzE0IDExLjIyIDI0LjkyNSAyNC45NDUgMjQuOTI1czI0Ljk0NS0xMS4yMSAyNC45NDUtMjQuOTI1Qzg0Ljk1MSA0My4yMTEgNzMuNzMxIDMyIDYwLjAwNiAzMnpNMTAyLjgxNiAxMDEuNzdjLS42NTMtMS42MzMtMS41MjUtMy4xNTYtMi41MDUtNC41NzItNS4wMTEtNy40LTEyLjc0NS0xMi4yOTktMjEuNDYtMTMuNDk2LTEuMDg4LS4xMDktMi4yODcuMTA5LTMuMTU4Ljc2Mi00LjU3NSAzLjM3NC0xMC4wMjIgNS4xMTUtMTUuNjg2IDUuMTE1LTUuNjY1IDAtMTEuMTExLTEuNzQxLTE1LjY4Ni01LjExNS0uODcyLS42NTMtMi4wNy0uOTgtMy4xNi0uNzYyYTMwLjc4NCAzMC43ODQgMCAwMC0yMS40NTggMTMuNDk2Yy0uOTggMS40MTYtMS44NTIgMy4wNDgtMi41MDYgNC41NzItLjMyNi42NTMtLjIxOCAxLjQxNS4xMSAyLjA2OC44NyAxLjUyNCAxLjk2IDMuMDQ4IDIuOTQgNC4zNTRhNDEuNDE4IDQxLjQxOCAwIDAwNS4wMTEgNS42NmMxLjUyNSAxLjUyMyAzLjI2OCAyLjkzOCA1LjAxIDQuMzUzIDguNjA2IDYuNDIyIDE4Ljk1NSA5Ljc5NiAyOS42MyA5Ljc5NnMyMS4wMjMtMy4zNzQgMjkuNjI5LTkuNzk2YTQ3Ljk1NyA0Ny45NTcgMCAwMDUuMDEtNC4zNTNjMS43NDQtMS43NDIgMy40ODYtMy41OTIgNS4wMTItNS42NiAxLjA4OS0xLjQxNSAyLjA2OS0yLjgzIDIuOTQxLTQuMzU0LjU0NC0uNjUzLjY1My0xLjQxNS4zMjYtMi4wNjh6Ii8+PC9nPjwvc3ZnPg==');

//
//  Common
//  --------------------------------------------

.amblog-author-info {
    .amblog-content {
        @include am-word-break;

        padding: $amblog-author-info__padding;
        text-align: center;
    }

    .amblog-image {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .amblog-name {
        margin: 0;
        color: $amblog-gray__color;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3.3rem;
    }

    .amblog-job {
        margin: 5px 0 0;
        color: $amblog-date__color;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .amblog-description {
        margin: 10px 0 0;
        color: $amblog-gray__color;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .amblog-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-sizing: border-box;
        margin: calc(#{$amblog-image-wrapper__size} / 2 + #{$amblog-author-info__padding}) auto 15px;
        min-width: $amblog-image-wrapper__size;
        width: $amblog-image-wrapper__size;
        height: $amblog-image-wrapper__size;
        border: 4px solid #fff;
        border-radius: 100%;
        background: $amblog-image-wrapper__background-color center/$amblog-image-wrapper__size no-repeat;
    }

    .amblog-image-wrapper.-placeholder {
        background-image: $amblog-image-wrapper__background-image;
    }
}

.amblog-author-info .amblog-social-container.amblog-list {
    .amblog-element-block[data-set='sidebar-author'],
    .amblog-item[data-set='content-author'] {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin: 15px 0 0;
    }

    .amblog-icon {
        margin: 0 10px;
        width: $amblog-social-icon__size;
        height: $amblog-social-icon__size;
        background-size: 18px;

        &:hover {
            filter: brightness(1.2);
        }
    }

    .amblog-icon.-facebook {
        background-color: #1877f2;
        background-position: 50% 100%;
        background-size: 17px;
    }

    .amblog-icon.-twitter {
        background-color: #55acee;
    }

    .amblog-icon.-linkedin {
        background-color: #0077b5;
    }

    .amblog-social {
        width: $amblog-social-icon__size;
        height: $amblog-social-icon__size;
    }
}

.amblog-index-author .desktop-block.amblog-element-block:first-of-type {
    margin-top: $amblog-image-wrapper__size / 2;
}

.amblog-index-author {
    .amblog-swipe.-left,
    .amblog-swipe.-right {
        top: 0;
    }

    .amblog-article-title {
        color: $amblog-gray__color;
        letter-spacing: -.025em;
        font-weight: 600;
        font-size: 3.5rem;
        line-height: 4.8rem;
    }
}

.amblog-main-content .amblog-author-info .amblog-title {
    display: none;
}

.right,
.left {
    .amblog-element-block[data-set='sidebar-author'] {
        padding: 0;
    }
}

// Override Magento styles
.amblog-index-author .page-main > .page-title-wrapper .page-title {
    display: none;
}

//
//  Mobile
//  --------------------------------------------

@include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
    .amblog-index-author {
        .amblog-swipe.-left,
        .amblog-swipe.-right {
            top: 85px;
        }
    }

    .amblog-author-info {
        .amblog-image-wrapper {
            margin-top: 0;
        }

        .amblog-content {
            padding-top: 5px;
        }
    }
}
