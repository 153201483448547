//
//  Comments styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../utils';

//
//  Variables
//  --------------------------------------------

$amblog-comment-highlight__color__classic: lighten($amblog-svg-custom-classic__color, 35%);
$amblog-comment-highlight__color__red: lighten($amblog-svg-custom-red__color, 35%);
$amblog-comment-highlight__color__green: lighten($amblog-svg-custom-green__color, 50%);
$amblog-comment-highlight__color__blue: lighten($amblog-svg-custom-blue__color, 48%);
$amblog-comment-highlight__color__grey: lighten($amblog-svg-custom-grey__color, 23%);
$amblog-comment-highlight__color__purple: lighten($amblog-svg-custom-purple__color, 55%);

//
//  Common
//  --------------------------------------------


.amblog-comments-container .amblog-comment.-highlighted {
    outline: 5px solid $amblog-comment-highlight__color__classic;
    background-color: $amblog-comment-highlight__color__classic;

    &.-classic {
        outline-color: $amblog-comment-highlight__color__classic;
        background-color: $amblog-comment-highlight__color__classic;
    }

    &.-red {
        outline-color: $amblog-comment-highlight__color__red;
        background-color: $amblog-comment-highlight__color__red;
    }

    &.-green {
        outline-color: $amblog-comment-highlight__color__green;
        background-color: $amblog-comment-highlight__color__green;
    }

    &.-blue {
        outline-color: $amblog-comment-highlight__color__blue;
        background-color: $amblog-comment-highlight__color__blue;
    }

    &.-grey {
        outline-color: $amblog-comment-highlight__color__grey;
        background-color: $amblog-comment-highlight__color__grey;
    }

    &.-purple {
        outline-color: $amblog-comment-highlight__color__purple;
        background-color: $amblog-comment-highlight__color__purple;
    }
}

.amblog-comments-container {
    margin: 30px 20px 0;

    .amblog-comment {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.amblog-comments-action .amblog-replies,
.amblog-comments-container .amblog-replies,
.amblog-replies-block:not(.-level-top) .amblog-comment-reply {
    margin: 20px 0 0 5px;
    padding-left: 5px;
    border-left: 1px solid $amblog-border__color;
}

.amblog-comment-content {
    padding-left: 35px;

    .amblog-author {
        position: relative;
        display: inline-block;
        margin: 0 20px 0 0;
        padding-left: 0;

        .amblog-svg.user {
            position: absolute;
            top: 0;
            left: -35px;
        }
    }

    .amblog-header {
        margin-bottom: 15px;
    }

    .amblog-date {
        display: inline-block;
        margin: 0;
        color: $amblog-date__color;
        font-size: 14px;
    }

    .amblog-willapprove {
        @include am-word-break;

        margin-top: 15px;
        padding: 0 10px;
        border-radius: 4px;
        background: #fff3e0;
        color: #f57c00;
    }

    .amblog-thesis {
        @include am-word-break;

        font-style: italic;
        font-size: 16px;
    }

    .amblog-reply {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    .amblog-button {
        font-size: 1.2rem;
    }
}

.amblog-comments-action {
    margin: 20px;
    padding: 20px;
    border: none;
    background: $amblog__background-color;

    > .amblog-comment {
        margin-bottom: 60px;
    }

    .amblog-button.-toggle {
        display: none;
    }
}

.amblog-comment-reply {
    margin: 30px 0;

    .amblog-button.-toggle {
        display: none;
    }
}

.amblog-form-head {
    margin-bottom: 20px;
    padding: 0 0 8px;
    border-bottom: 1px solid #c8c8c8;
    color: $amblog-date__color;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.amblog-comments-block:not(.-loaded) .amblog-reply {
    opacity: .5;
    pointer-events: none;
    user-select: none;

    .amblog-button.-toggle {
        display: none;
    }
}

.amblog-replies-block.-level-top > .amblog-comment-reply,
.amblog-replies-block.-level-top > .amblog-replies:not(:empty) {
    margin: 20px 0 0;
    padding: 20px;
    border: none;
    background: $amblog__background-color;
}

.amblog-replies-block.-level-top > .amblog-replies {
    &:not(.-active) {
        display: none;
    }
}

.amblog-reply .amblog-button.-toggle {
    position: relative;
    margin-left: 31px;

    &:before {
        position: absolute;
        right: calc(100% + 15px);
        width: 1px;
        height: 100%;
        background: #c4c4c4;
        content: '';
    }
}

//
//  Tablet
//  --------------------------------------------

@include respond-to-up($tweakpoint-nav-toggle) {
    .amblog-comments-container {
        margin: 30px 30px 0;
    }

    .amblog-comments-action {
        margin: 20px 30px;
    }

    .amblog-comments-action .amblog-replies,
    .amblog-comments-container .amblog-replies,
    .amblog-replies-block:not(.-level-top) .amblog-comment-reply {
        margin: 30px 0 0 35px;
        padding-left: 20px;
    }
}
