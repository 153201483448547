//
//  Buttons styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../utils';

//
//  Variables
//  --------------------------------------------

$amblog-button__color: $amblog-blue__color;
$amblog-button__background-color: #e4effb;

//
//  Common
//  --------------------------------------------

.amblog-button {
    @include am-word-break;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 10px;
    height: 25px;
    outline: none;
    border: 1px solid $amblog-button__color;
    border-radius: 2px;
    background: $amblog-button__background-color;
    box-shadow: none;
    color: $amblog-button__color;
    font-weight: $font-weight__regular;

    &:hover,
    &:focus {
        color: $amblog-button__color;
        text-decoration: none;
    }

    &:hover {
        background: darken($amblog-button__background-color, 10%);
    }

    &:not(.primary),
    &:not(.primary):active {
        box-shadow: none;
    }

    &.-clear {
        padding: 0;
        border: none;
        background: none;
    }
}

.amblog-button > .amblog-icon {
    margin-right: 5px;
    fill: $amblog-button__color;
}

.amblog-button.-toggle {
    line-height: 1.1;

    .amblog-icon {
        position: relative;
        top: 1px;
    }

    &.-active .amblog-icon {
        transform: rotate(180deg);
    }
}

.amblog-button > .amblog-count:not(:empty) {
    padding-left: 3px;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

.amblog-reply .amblog-button {
    @extend %button-base;
    @extend %button-murmur;
    height: auto;
    width: auto;

    > svg {
        fill: currentColor;
    }
}

.amblog-link,
.post-item-link {
    color: $amblog-link__color;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    font-weight: bold;

    &:hover {
        color: $amblog-link__hover__color;
    }
}
